import { gql } from '@apollo/client'

import { PERSON_FRAGMENT } from '../fragments/person-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/c/hJuzHEzR
 * GQL Schema: https://github.com/assuranceiq/journey-client-backend/blob/main/client_backend/graphql/schema.graphql
 */
export const SHOPPERS_QUERY = gql`
  query Shoppers($filter: ShopperFilter!, $actions: Int) {
    shoppers(filter: $filter) {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`
