import { Link as RouterLink } from 'react-router-dom'

import { Chip } from '@mui/material'
import { SxProps } from '@mui/system'

import styles from './JourneyNameChip.module.scss'

interface JourneyNameChipProps {
  name: string
  sx?: SxProps
}

export function JourneyNameChip(props: JourneyNameChipProps) {
  const { name = '', sx = {} } = props
  return (
    <Chip
      className={styles.root}
      label={name}
      size='small'
      color='success'
      component={RouterLink}
      to={`/journeys/${name}`}
      sx={{ ml: 0.75, color: 'white', ...sx }}
    />
  )
}
