import { gql } from '@apollo/client'

export const FORM_FRAGMENT = gql`
  fragment FormFragment on Form {
    id
    createdAt
    updatedAt
    identifier
    shopperAnswers {
      id
      createdAt
      updatedAt
      question
      answers {
        id
        createdAt
        updatedAt
        answer
      }
    }
  }
`
