import { gql } from '@apollo/client'

export const AGENT_FRAGMENT = gql`
  fragment AgentFragment on Person {
    id
    createdAt
    updatedAt
    identifier
    email
    telephones
    familyName
    givenName
    url
  }
`
