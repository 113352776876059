import _ from 'lodash'

import type { Person } from '../types'
import { formatPhoneNumber, isValidPhone } from './number'

/**
 * Takes a query string from the user and guesses which filter param to use for the shopper query.
 * A little UI magic.
 * Returns a ShopperFilter object.
 */
export function createFilter(query: string) {
  if (query.includes('@')) {
    return { email: query }
  }
  if (isValidPhone(query)) {
    return { telephone: formatPhoneNumber(query, '') } // convert to format that API accepts
  }
  if (/^\s*\d{2,6}\s*$/.test(query)) {
    return { joinAgentId: query }
  }
  // if the criteria above not met, then assume it's a lead ID
  return { leadId: query }
}

/**
 * Creates and returns a URL query param for the given shopper.
 * ex) { email: 'me@example.com' } -> "/?q=me%40example.com"
 */
export function createShopperUrl(shopper: Partial<Person>) {
  let url = '/?'
  if (shopper.leads?.length) {
    url += new URLSearchParams({ q: _.first(shopper.leads)?.leadId || 'unknown' })
  } else if (shopper.email) {
    url += new URLSearchParams({ q: shopper.email })
  } else if (shopper.telephones?.length) {
    url += new URLSearchParams({ q: _.first(shopper.telephones) || 'unknown' })
  } else {
    url += 'q=empty'
  }
  return url
}

/**
 * Creates and returns a URL query param for the given query string.
 * ex) "123456" -> "/?q=123456"
 */
export function createQueryUrl(queryStr: string) {
  let url = '/'
  if (queryStr) {
    url += '?' + new URLSearchParams({ q: queryStr })
  }
  return url
}
