import { Typography } from '@mui/material'

export function NotFound() {
  return (
    <div>
      <Typography variant='h6' gutterBottom>
        Not Found
      </Typography>
      <Typography variant='body2'>Uh oh, you seem to be lost!</Typography>
    </div>
  )
}
