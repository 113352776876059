import { Chip, Divider, Paper, Typography } from '@mui/material'
import type { SxProps } from '@mui/system'

import _ from 'lodash'

import { AgentLink } from '../AgentLink'
import type { Person, Policy } from '../../types'

import styles from './PolicyCard.module.scss'

interface PolicyCardProps {
  policy: Policy
}

export function PolicyCard({ policy }: PolicyCardProps) {
  const status = _.trim(policy.policy?.status)

  // determine Chip styling
  let sx: SxProps = { ml: 0.75 }
  let chipColor = 'default'
  if (status === 'Active') {
    chipColor = 'success'
    sx = { ...sx, color: 'white' }
  } else if (status === 'Not Active') {
    chipColor = 'error'
  }

  return (
    <Paper className={styles.root} elevation={3}>
      <div className={styles.title}>
        <Typography variant='button'>{policy.policy?.name || 'Unknown Policy'}</Typography>
        {status && <Chip label={status} color={chipColor as any} size='small' sx={sx} />}
      </div>

      <Typography
        variant='overline'
        color='text.secondary'
        component='div'
        sx={{ lineHeight: 'inherit', mb: 1 }}
      >
        {policy.policy?.provider?.name || 'Unknown Provider'}
      </Typography>

      <Divider sx={{ my: 1.5 }} />

      <Field label='Policy ID'>{policy.policy?.policyId || 'N/A'}</Field>
      <Field label='Enrollment Status'>
        {policy.policy?.enrollmentStatus ? policy.policy?.enrollmentStatus : 'N/A'}
      </Field>
      <Field label='Effective Date'>
        {policy.startDate ? _.take(policy.startDate, 10) : 'N/A'}
      </Field>
      <Field label='Expiration Date'>{policy.endDate ? _.take(policy.endDate, 10) : 'N/A'}</Field>
      <Field label='Agent'>
        {policy.agent ? (
          <AgentLink
            agent={policy.agent as Person}
            disambiguatingDescription={policy.disambiguatingDescription}
          />
        ) : (
          'N/A'
        )}
      </Field>
    </Paper>
  )
}

function Field({ label, children }) {
  return (
    <div>
      <strong>{label}:</strong> {children}
    </div>
  )
}
