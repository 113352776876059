import _ from 'lodash'

const CRM_ADMIN_LEAD_URL_PREFIX = 'https://assurance.com/admin/leads/'
const JOIN_ADMIN_AGENT_URL_PREFIX = 'https://join.assurance.com/admin/agents/'

export function createCrmLeadUrl(leadId: string): string {
  return CRM_ADMIN_LEAD_URL_PREFIX + leadId
}

export function createJoinAgentUrl(joinAgentId: string): string {
  return JOIN_ADMIN_AGENT_URL_PREFIX + joinAgentId
}

export function getJoinId(url: string): string {
  return _.split(url, JOIN_ADMIN_AGENT_URL_PREFIX).pop() || ''
}

export function findWindowLocationParameter(param: string) {
  let value: string | null = null

  window.location.search
    .substring(1)
    .split('&')
    .forEach(function (item) {
      const tmp = item.split('=')
      if (tmp[0] === param) value = decodeURIComponent(tmp[1])
    })
  return value
}
