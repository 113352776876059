import { gql } from '@apollo/client'

import { COMMUNICATE_ACTION_FRAGMENT } from './communicate-action-fragment'
import { FORM_FRAGMENT } from './form-fragment'
import { JOURNEY_FRAGMENT } from './journey-fragment'
import { POLICY_FRAGMENT } from './policy-fragment'

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    id
    createdAt
    updatedAt
    email
    telephones
    leads {
      leadId
      leadType
    }
    communicateActions(limit: $actions) {
      ...CommunicateActionFragment
    }
    journeys {
      ...JourneyFragment
      forms {
        ...FormFragment
      }
    }
    policies {
      ...PolicyFragment
    }
  }
  ${JOURNEY_FRAGMENT}
  ${FORM_FRAGMENT}
  ${COMMUNICATE_ACTION_FRAGMENT}
  ${POLICY_FRAGMENT}
`
