import React, { useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material'
import { OpenInNew } from '@mui/icons-material'

import _ from 'lodash'
import clsx from 'clsx'

import { AgentTimeline } from '../AgentTimeline'
import LogRocket from '../../utils/logrocket'
import { LoiIcon } from '../LoiIcon'
import type { Person } from '../../types'
import { PolicyCard } from '../PolicyCard'
import { ShopperTimeline } from '../ShopperTimeline'
import { createCrmLeadUrl, createJoinAgentUrl } from '../../utils/url'
import { formatPhoneNumber } from '../../utils/number'
import { humanizeTimeDiff } from '../../utils/date'

import styles from './Profile.module.scss'

interface ProfileProps {
  person: Person
  rawData?: any // needed to support agent summary (not nested inside person object unlike shopper)
}

export function Profile(props: ProfileProps) {
  const { person, rawData } = props

  const [tabValue, setTabValue] = useState<number>(0)
  const [leadIdsToShow, setLeadIdsToShow] = useState<string[]>(_.map(person.leads, 'leadId'))

  // need this effect to really make sure the leadIds change too when the person object changes
  // React likes to hold onto the state, especially when pressing the back button in the browser
  useEffect(() => {
    setLeadIdsToShow(_.map(person.leads, 'leadId'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person])

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  const handleLeadIdsChange = (newLeadIds: string[]) => {
    LogRocket.track('FilterLeadIds') // we want to monitor how people are using this
    setLeadIdsToShow(newLeadIds)
  }

  const isAgentProfile = !!person.joinAgentId

  const policyTabLabel = (
    <Box display='flex' alignItems='center'>
      Policies
      {!_.isEmpty(person.policies) && (
        <Chip size='small' color='secondary' label={person.policies?.length} sx={{ ml: 0.5 }} />
      )}
    </Box>
  )

  return (
    <div className={styles.root}>
      <Sidebar
        person={person}
        leadIdsToShow={leadIdsToShow}
        handleLeadIdsChange={handleLeadIdsChange}
      />
      <div className={clsx(styles.right, 'animate__animated animate__fadeInRight')}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label='Timeline' />
            <Tab label={policyTabLabel} disabled={isAgentProfile || _.isEmpty(person.policies)} />
            <Tab label='JSON' />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          {isAgentProfile ? (
            <AgentTimeline data={rawData} />
          ) : (
            <ShopperTimeline shopper={person} leadIdsToShow={leadIdsToShow} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {!_.isEmpty(person.policies) &&
            person.policies?.map(policy => <PolicyCard key={policy.id} policy={policy} />)}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <pre>{JSON.stringify(rawData, null, 2)}</pre>
        </TabPanel>
      </div>
    </div>
  )
}

function Sidebar({ person, leadIdsToShow, handleLeadIdsChange }) {
  const isAgentProfile = !!person.joinAgentId
  const title = isAgentProfile ? 'Matched Agent' : 'Matched Shopper'
  return (
    <div className={clsx(styles.left, 'animate__animated animate__fadeInLeft')}>
      <Typography variant='h6' sx={{ mt: 0.5, mb: 1.5 }}>
        {title}
      </Typography>

      <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
        Name
      </Typography>
      {person.givenName ? (
        <Typography variant='body2'>{`${person.givenName} ${person.familyName}`}</Typography>
      ) : (
        <Typography variant='body2'>N/A</Typography>
      )}

      <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
        Email Address
      </Typography>
      <Typography variant='body2'>{person.email || 'N/A'}</Typography>

      <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
        Phone Number(s)
      </Typography>
      {!person.telephones?.length && <Typography variant='body2'>N/A</Typography>}
      {person.telephones.map(telephone => (
        <Typography variant='body2' key={telephone}>
          {formatPhoneNumber(telephone)}
        </Typography>
      ))}

      {!isAgentProfile && (
        <>
          <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
            Lead ID(s)
          </Typography>

          {!person.leads?.length && <Typography variant='body2'>N/A</Typography>}

          {person.leads?.length > 1 && (
            <Box className={styles.leadIdControls}>
              <Link
                underline='hover'
                color={person.leads.length === leadIdsToShow.length ? 'textSecondary' : 'primary'}
                onClick={() => handleLeadIdsChange(_.map(person.leads, 'leadId'))}
              >
                SELECT ALL
              </Link>
              <Box className={styles.divider}>|</Box>
              <Link
                underline='hover'
                color={person.leads.length === leadIdsToShow.length ? 'primary' : 'textSecondary'}
                onClick={() => handleLeadIdsChange([])}
              >
                CLEAR ALL
              </Link>
            </Box>
          )}

          {person.leads.map(({ leadId, leadType }) => (
            <div key={leadId} className={styles.leadIdRow}>
              <FormGroup>
                <FormControlLabel
                  className={styles.label}
                  label={
                    <>
                      <LoiIcon loi={leadType} />
                      {leadId}
                    </>
                  }
                  control={
                    <Checkbox
                      className={styles.checkbox}
                      size='small'
                      checked={leadIdsToShow.includes(leadId)}
                      onChange={e =>
                        handleLeadIdsChange(
                          e.target.checked
                            ? [...leadIdsToShow, leadId]
                            : _.without(leadIdsToShow, leadId)
                        )
                      }
                    />
                  }
                  disableTypography
                />
              </FormGroup>

              <Tooltip title='Open in CRM' placement='top' arrow>
                <Link
                  className={styles.link}
                  variant='body2'
                  href={createCrmLeadUrl(leadId)}
                  target='_blank'
                >
                  <OpenInNew className={styles.linkIcon} color='success' />
                </Link>
              </Tooltip>

              <Link
                className={styles.onlyLink}
                underline='hover'
                onClick={() => handleLeadIdsChange([leadId])}
              >
                only
              </Link>
            </div>
          ))}
        </>
      )}

      {isAgentProfile && (
        <>
          <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
            Agent ID
          </Typography>
          {!person?.joinAgentId && <Typography variant='body2'>N/A</Typography>}
          {person?.joinAgentId && (
            <Tooltip title='Open in Join' placement='right' arrow>
              <Link
                className={styles.link}
                variant='body2'
                href={createJoinAgentUrl(person.joinAgentId)}
                target='_blank'
              >
                {person.joinAgentId}
                <OpenInNew className={styles.linkIcon} color='success' />
              </Link>
            </Tooltip>
          )}
        </>
      )}

      <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
        Created
      </Typography>
      <Typography variant='body2'>{humanizeTimeDiff(person.createdAt || '') || 'N/A'}</Typography>

      <Typography variant='subtitle2' color='primary' sx={{ mt: 1.5 }}>
        Updated
      </Typography>
      <Typography variant='body2'>{humanizeTimeDiff(person.updatedAt || '') || 'N/A'}</Typography>
    </div>
  )
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  )
}
