import React from 'react'

import _ from 'lodash'

import type { CommunicateAction, Person } from '../../types'
import {
  getCallCampaignSummaries,
  getDncActions,
  getEmailActions,
  getJourneyEventStatuses,
  getJourneyForms,
  getMissedCallSummaries,
  getPipelineAppointments,
  getSmsActions,
  getTelephoneActions,
  insertTimeDividers
} from '../../utils/timeline'

interface ShopperTimelineProps {
  shopper: Person
  hideEvents?: boolean // hide journey status events; only show communications
  leadIdsToShow?: string[] // optional lead ID filter
}

export function ShopperTimeline(props: ShopperTimelineProps) {
  const { shopper, hideEvents = false, leadIdsToShow } = props
  const isShopper = (person: Person) => person?.id === shopper.id

  let actions = consolidateCommunicateActions(shopper)

  // filter by Lead IDs, when applicable
  if (leadIdsToShow != null) {
    actions = _.filter(actions, o => !o.leadId || leadIdsToShow.includes(o.leadId))
  }

  let activityCards: React.ReactNode[] = []

  activityCards.push(...getEmailActions(actions))
  activityCards.push(...getDncActions(actions))
  activityCards.push(...getTelephoneActions(actions, isShopper))
  activityCards.push(...getSmsActions(actions))
  activityCards.push(...getMissedCallSummaries(actions))
  activityCards.push(...getJourneyForms(shopper.journeys || []))
  activityCards.push(...getCallCampaignSummaries(shopper.journeys || []))
  if (!hideEvents) {
    activityCards.push(...getJourneyEventStatuses(shopper.journeys || [], leadIdsToShow))
  }
  activityCards.push(...getPipelineAppointments(actions))

  // sort chronologically (most recent first)
  activityCards = _.sortBy(activityCards, [
    o => {
      const startTime = o?.['props'].startTime
      if (!startTime) {
        console.error('"startTime" prop is missing from ActivityCard. This should not happen!')
      }
      return new Date(startTime)
    }
  ]).reverse()

  // insert TimeDivider whenever there's a big time gap between activities
  activityCards = insertTimeDividers(activityCards)

  if (!activityCards.length) return <div>Nothing to show here..!</div>

  return <div>{activityCards}</div>
}

/**
 * Consolidate all communicateActions from the given shopper:
 * - shopper.communicateActions
 * - shopper.journeys
 *
 * Assign journeyName if available.
 */
function consolidateCommunicateActions(shopper: Person): CommunicateAction[] {
  const communicateActions = _.cloneDeep(shopper.communicateActions) || []
  _.each(shopper.journeys, journey => {
    const journeyName = journey.isPartOf?.name || ''
    _.each(journey.communicateActions, action => {
      const index = _.findIndex(communicateActions, { id: action.id })
      if (index === -1) {
        const newAction = { ...action, journeyName: journeyName }
        communicateActions.push(newAction)
      } else {
        communicateActions[index].journeyName = journeyName
      }
    })
  })
  return communicateActions
}
