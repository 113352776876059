import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/Mqr6hwss
 * GQL Schema: https://github.com/assuranceiq/journey-client-backend/blob/main/client_backend/graphql/schema.graphql
 */
export const JOURNEY_TYPES_QUERY = gql`
  query {
    journeyTypes {
      id
      name
      createdAt
    }
  }
`
