import { gql } from '@apollo/client'

import { AGENT_FRAGMENT } from './agent-fragment'
import { COMMUNICATE_ACTION_MESSAGE_FRAGMENT } from './communicate-action-message-fragment'
import { ORGANIZATION_FRAGMENT } from './organization-fragment'
import { PIPELINE_APPOINTMENT_FRAGMENT } from './pipeline-appointment-fragment'
import { PIPELINE_OPPORTUNITY_FRAGMENT } from './pipeline-opportunity-fragment'
import { PIPELINE_REMINDER_FRAGMENT } from './pipeline-reminder-fragment'

export const COMMUNICATE_ACTION_FRAGMENT = gql`
  fragment CommunicateActionFragment on CommunicateAction {
    id
    createdAt
    updatedAt
    about
    actionStatus
    endTime
    identifier
    instrument
    leadId
    leadType
    name
    startTime
    disambiguatingDescription
    url
    error
    missedCallCount
    agent {
      ...AgentFragment
      ...OrganizationFragment
    }
    location {
      id
      telephone
    }
    object {
      ...CommunicateActionMessageFragment
      ...PipelineAppointmentFragment
      ...PipelineOpportunityFragment
      ...PipelineReminderFragment
    }
    recipient {
      ...AgentFragment
      ...OrganizationFragment
    }
  }
  ${AGENT_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  ${COMMUNICATE_ACTION_MESSAGE_FRAGMENT}
  ${PIPELINE_OPPORTUNITY_FRAGMENT}
  ${PIPELINE_APPOINTMENT_FRAGMENT}
  ${PIPELINE_REMINDER_FRAGMENT}
`
