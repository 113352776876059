import { Route } from '@mui/icons-material'

import { ActivityCard } from '../ActivityCard'
import { JourneyNameChip } from '../../JourneyNameChip'
import type { JourneyStatus } from '../../../types'
import { JourneyStatusType } from '../../../enums'

interface JourneyStatusCardProps {
  status: JourneyStatus
  journeyName: string
  startTime: string // note: this prop is required for Timeline sorting to work
}

interface Metadata {
  label: string
  value?: string
}

export function JourneyStatusCard(props: JourneyStatusCardProps) {
  const { status, journeyName, startTime } = props

  const metadata: Metadata[] = []
  if (status.code !== status.status) metadata.push({ label: 'code', value: status.code })
  if (status.description) metadata.push({ label: 'description', value: status.description })

  return (
    <ActivityCard
      startTime={startTime}
      icon={Route}
      variant={status.status === JourneyStatusType.FAILED ? 'error' : 'info'}
      leadId={status.leadId}
      leadType={status.leadType}
    >
      Journey <JourneyNameChip name={journeyName} sx={{ ml: 0 }} /> {displayStatus(status.status)}{' '}
      {displayMetadata(metadata)}
    </ActivityCard>
  )
}

function displayStatus(status: JourneyStatusType) {
  if (status === JourneyStatusType.SUCCEEDED) return 'completed'
  return status
}

function displayMetadata(metadata: Metadata[]) {
  if (!metadata.length) return null
  return (
    <>
      (
      {metadata.map(({ label, value }, i) => (
        <span key={label}>
          {i > 0 && ', '}
          {label}: <b>{value || '-'}</b>
        </span>
      ))}
      )
    </>
  )
}
