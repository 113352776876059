import { Box, Chip, Typography } from '@mui/material'

import clsx from 'clsx'

import styles from './IntroCard.module.scss'

export function IntroCard() {
  return (
    <div className={clsx(styles.root, 'animate__animated animate__slideInLeft')}>
      <Typography variant='h6'>Welcome to Journey Hub!</Typography>
      <Box mt={1}>
        To see the journey of a shopper or agent, enter one of these things into the search bar
        above:
        <ul>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Lead ID</li>
          <li>
            Join Agent ID <Chip label='Experimental' size='small' sx={{ mt: '-2px' }} />
          </li>
        </ul>
      </Box>
    </div>
  )
}
