import { Route, Routes } from 'react-router-dom'

import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { ApolloProvider } from '@apollo/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { DataDogRUMProvider, SnackbarProvider } from '@assuranceiq/react-components'
import { StoreProvider } from 'easy-peasy'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_RESPONSE_TYPE,
  AUTH0_SCOPE,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_VERSION
} from './utils/environment-variables'
import { ActivityFeed } from './pages/ActivityFeed'
import Authenticate from './navigation/Authenticate'
import { Home } from './pages/Home'
import { Journey } from './pages/Journey'
import { Layout } from './navigation/Layout'
import { NotFound } from './pages/NotFound'
import { createApolloClient } from './graphql/apollo-client'
import { createStore } from './utils/store'
import theme from './styles/theme'

import './styles/global.scss'

const client = createApolloClient()
const store = createStore()

export const App = function () {
  const onRedirectCallback = (appState?: { returnTo?: any }) => {
    // redirect to the page where user was before
    if (appState && appState.returnTo) {
      window.history.replaceState(null, '', appState.returnTo)
    }
  }
  console.log('Rendering App')
  return (
    <StoreProvider store={store}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        audience={AUTH0_AUDIENCE}
        responseType={AUTH0_RESPONSE_TYPE}
        scope={AUTH0_SCOPE}
        redirectUri={window.document.location.origin}
        onRedirectCallback={onRedirectCallback as any}
        post
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <SnackbarProvider>
              <DataDogRUMProvider
                applicationId={DD_APPLICATION_ID}
                clientToken={DD_CLIENT_TOKEN}
                service={DD_SERVICE}
                env={DD_ENV}
                sampleRate={DD_SAMPLE_RATE}
                trackInteractions={true}
                version={DD_VERSION}
              >
                <Authenticate>
                  <ApolloProvider client={client}>
                    <Layout>
                      <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/journeys' element={<Home />} />
                        <Route path='/journeys/:journeyName' element={<Journey />} />
                        <Route path='/live-feed' element={<ActivityFeed />} />
                        <Route path='/*' element={<NotFound />} />
                      </Routes>
                    </Layout>
                  </ApolloProvider>
                </Authenticate>
              </DataDogRUMProvider>
            </SnackbarProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </Auth0Provider>
    </StoreProvider>
  )
}
