import { Link as RouterLink } from 'react-router-dom'

import { Link, Tooltip } from '@mui/material'

import _ from 'lodash'

import type { PersonOrOrganization } from '../../types'
import { createQueryUrl } from '../../utils/search-query'
import { getJoinId } from '../../utils/url'

import styles from './AgentLink.module.scss'

export const ROLES = [
  'agent',
  'account_manager',
  'customer_care_advocate',
  'customer_care_health',
  'enrollment_specialist',
  'guide_rep',
  'inbound_guide_rep',
  'pc_customer_service',
  'payment_specialist',
  'policy_activation_coordinator'
]

interface AgentLinkProps {
  agent: PersonOrOrganization
  disambiguatingDescription: string | null | undefined
}

export function AgentLink(props: AgentLinkProps) {
  const { agent, disambiguatingDescription } = props

  const roles = _.split(disambiguatingDescription, ',')
    .filter(x => ROLES.includes(x))
    .map(_.startCase)

  const name = getDisplayName(agent, roles)
  const tooltip = getTooltipText(agent, roles)
  const joinId = getJoinId(agent['url'])

  // if no URL available, then just italicize the name
  if (!agent['url']) {
    return (
      <Tooltip title={tooltip} placement='top' arrow>
        <div className={styles.root}>
          <em>{name}</em>
        </div>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={tooltip} placement='top' arrow>
      <div className={styles.root}>
        {joinId ? (
          <Link component={RouterLink} to={createQueryUrl(joinId)}>
            {name}
          </Link>
        ) : (
          <Link href={agent['url']} target='_blank'>
            {name}
          </Link>
        )}
      </div>
    </Tooltip>
  )
}

function getDisplayName(agent: PersonOrOrganization, roles: string[]): string {
  let name = agent['id']
  if (!_.isEmpty(roles)) {
    name = _.first(roles) // show first role (and the rest in the tooltip)
    if (agent['givenName'] || agent['familyName']) {
      name += ` ${getInitials(agent)}`
    }
  } else if (agent['givenName'] || agent['familyName']) {
    name = getFullName(agent)
  } else if (agent['email']) {
    name = agent['email']
  } else if (agent['name']) {
    name = agent['name'] // for organization
  } else if (agent['identifier']) {
    name = agent['identifier']
  } else {
    name = _.split(name, '/ids/').pop() // just show person ID, not the whole URL (if applicable)
  }
  return name
}

function getTooltipText(agent: PersonOrOrganization, roles: string[]): string {
  let tooltip = ''
  if (agent['givenName'] || agent['familyName']) {
    tooltip = getFullName(agent)
  }

  const hasName = !_.isEmpty(tooltip.trim())
  if (hasName) tooltip += ' '

  const rolesStr = roles.join(', ') || 'unknown roles'
  tooltip += `(${rolesStr})`

  return tooltip
}

function getInitials(agent: PersonOrOrganization): string {
  return `${_.first(agent['givenName']) || ''}${_.first(agent['familyName']) || ''}`
}

function getFullName(agent: PersonOrOrganization): string {
  let name = agent['givenName'] || ''
  if (agent['givenName'] && agent['familyName']) name += ' '
  name += agent['familyName'] || ''
  return name
}
