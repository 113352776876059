import { gql } from '@apollo/client'

import { PERSON_FRAGMENT } from '../fragments/person-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/c/R0ktBrD8
 * GQL Schema: https://github.com/assuranceiq/journey-client-backend/blob/main/client_backend/graphql/schema.graphql
 */
export const ACTIVITY_FEED_QUERY = gql`
  query ShopperActivityFeed($first: Int!, $after: String, $actions: Int) {
    shopperActivityFeed(first: $first, after: $after) {
      edges {
        node {
          ...PersonFragment
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PERSON_FRAGMENT}
`
