import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Alert, Box, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material'
import { PauseCircle, PlayCircle } from '@mui/icons-material'

import { Loading } from '@assuranceiq/react-components'
import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { ACTIVITY_FEED_QUERY } from '../graphql/queries/activity-feed'
import { ShopperTimeline } from '../components/ShopperTimeline'
import { createShopperUrl } from '../utils/search-query'

import styles from './ActivityFeed.module.scss'

const POLL_INTERVAL_MS = 1000

export function ActivityFeed() {
  const [paused, setPaused] = useState<boolean>(false)
  const [pausedData, setPausedData] = useState([])

  const { loading, error, data } = useQuery(ACTIVITY_FEED_QUERY, {
    variables: { first: 10, actions: 1 },
    pollInterval: paused ? 0 : POLL_INTERVAL_MS
  })

  // we freeze the current data when the pause button is pressed to prevent
  // any in-flight request from updating the view when it finishes asynchronously
  useEffect(() => {
    if (paused) setPausedData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused])

  if (error) console.error('Error while querying activity feed!', error)

  const shoppers = _.get(paused ? pausedData : data, 'shopperActivityFeed.edges', []).map(
    o => o.node
  )
  const timelines = _.flatMap(shoppers, shopper => (
    <RouterLink className={styles.activity} key={shopper.id} to={createShopperUrl(shopper)}>
      <ShopperTimeline shopper={shopper} hideEvents />
    </RouterLink>
  ))

  return (
    <Box className='animate__animated animate__fadeIn' display='flex' flexDirection='column'>
      <Typography variant='h6' display='block'>
        Live Feed
      </Typography>
      <Box mt={1}>Here you can monitor all shopper activities and journey events in real-time.</Box>

      {loading ? (
        <Loading />
      ) : (
        <div className={styles.activityFeed}>
          <LinearProgress variant={paused ? 'determinate' : 'indeterminate'} />
          <Box p={1}>{timelines}</Box>
          <div className={styles.controls}>
            <Tooltip title={paused ? 'Resume' : 'Pause'} placement='top' arrow>
              <IconButton color={paused ? 'warning' : 'primary'} onClick={() => setPaused(!paused)}>
                {paused ? <PlayCircle /> : <PauseCircle />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      <Alert severity='info' sx={{ mt: 1.5 }}>
        If things are moving too fast, especially during business hours, you can utilize the pause
        button on the right.
      </Alert>
    </Box>
  )
}
