import type { User } from '@auth0/auth0-spa-js'
import _ from 'lodash'
import logrocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import { LOGROCKET_APP_ID } from './environment-variables'

const LogRocket = {
  init: function () {
    if (!LOGROCKET_APP_ID) return
    logrocket.init(LOGROCKET_APP_ID)
    setupLogRocketReact(logrocket)
  },

  identify: function (user: User) {
    if (!LOGROCKET_APP_ID) return
    logrocket.identify(user.sub || '', {
      name: user.name || user.email || '',
      email: user.email || '',
      picture: user.picture || '',
      roles: _.join(user['https://assurance.com/roles'], ',')
    })
  },

  track: function (eventName: string, eventProperties?: TrackEventProperties) {
    if (!LOGROCKET_APP_ID) return
    logrocket.track(eventName, eventProperties)
  }
}

// copied from 'logrocket' lib (since it doesn't expose the type)
type TrackEventProperties = {
  revenue?: number
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined
}

export default LogRocket
