import _ from 'lodash'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(relativeTime)

/**
 * Returns a humanized string for the time difference between now and the given date (ISO string).
 * ex) "In a minute", "A month ago", "In 2 months", "9 days ago", etc.
 */
export function humanizeTimeDiff(dateTo: string): string {
  if (!dateTo) return ''
  const isoDateStr = sanitizeISOString(dateTo)
  const secondsTo = (new Date(isoDateStr).getTime() - Date.now()) / 1000
  const humanized = dayjs.duration(secondsTo, 'seconds').humanize(true)
  return _.upperFirst(humanized)
}

/**
 * Returns a humanized string for the given ISO 8601 duration.
 * ex) "PT1H11M11S" -> "1h 11m 11s"
 * ex) "PT1M35S" -> "1m 35s"
 *
 * Caveat: durations longer than 24 hours not supported (probably unrealistic)
 * ex) "P3Y6M4DT12H30M5S" -> "24+ hr"
 *
 * Learn more about the ISO 8601 format:
 * https://en.wikipedia.org/wiki/ISO_8601#Durations
 */
export function humanizeDuration(duration: string): string {
  if (!duration) return ''
  const [left, right] = duration.split('T')
  if (left?.length > 1) return '24+ hr'
  return right.replace('H', 'h ').replace('M', 'm ').replace('S', 's ').trim()
}

/**
 * Sanitizes the datetime string and returns a valid ISO string with timezone.
 * We do this because the backend returns inconsistent semi-valid ISO strings for dates.
 *
 * ex) "2022-05-18T22:38:11" -> "2022-05-18T22:38:11Z" (add Z; assume UTC)
 * ex) "2022-05-18T22:38:11Z" -> "2022-05-18T22:38:11Z" (already valid; no change)
 * ex) "2022-05-18T22:38:11+00:00" -> "2022-05-18T22:38:11+00:00" (already valid; no change)
 */
export function sanitizeISOString(isoDateStr: string): string {
  if (!isoDateStr) return ''
  if (isoDateStr.endsWith('Z')) return isoDateStr
  if (isoDateStr.includes('+')) return isoDateStr
  return isoDateStr + 'Z'
}
