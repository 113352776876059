import { Chip, Divider } from '@mui/material'

import _ from 'lodash'
import dayjs from 'dayjs'

import { sanitizeISOString } from '../../utils/date'
import { withOrdinalSuffix } from '../../utils/number'

import styles from './TimeDivider.module.scss'

interface TimeDividerProps {
  datetime: string // ISO date string
}

export function TimeDivider(props: TimeDividerProps) {
  const { datetime } = props

  const isoDateStr = sanitizeISOString(datetime)
  const dayOfMonth = _.toNumber(dayjs(isoDateStr).format('D'))
  const year = _.toNumber(dayjs(isoDateStr).format('YYYY'))
  const currentYear = new Date().getFullYear()

  let label = dayjs(isoDateStr).format('dddd, MMMM ')
  label += withOrdinalSuffix(dayOfMonth)
  if (year !== currentYear) label += `, ${year}`

  return (
    <Divider className={styles.root}>
      <Chip label={label} variant='outlined' />
    </Divider>
  )
}
