import { Box, Skeleton } from '@mui/material'

import _ from 'lodash'

interface ListItemSkeletonProps {
  repeat?: number
}

export function ListItemSkeleton(props: ListItemSkeletonProps) {
  const { repeat = 1 } = props

  const titleWidth = 121
  const topWidth = 250
  const bottomWidth = 140

  return (
    <Box mt={3} ml={1.5}>
      <Skeleton variant='rectangular' width={titleWidth} height={16} sx={{ mb: 4 }} />

      {_.range(repeat).map(i => (
        <Box key={i} display='flex' mb={3.1}>
          <Skeleton variant='circular' width={35} height={35} />

          <Box ml={3.1}>
            <Skeleton variant='rectangular' width={topWidth} height={16} sx={{ mt: -0.3 }} />
            <Skeleton variant='rectangular' width={bottomWidth} height={16} sx={{ mt: 1.4 }} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
