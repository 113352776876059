import { Box, Link } from '@mui/material'

import clsx from 'clsx'

import styles from './FooterCard.module.scss'

export function FooterCard() {
  return (
    <div className={clsx(styles.root, 'animate__animated animate__slideInLeft animate__delay-1s')}>
      <Box>
        Made with ❤️ by the Omni Channel Team:
        <ul>
          <li>
            <strong>Director:</strong>{' '}
            <Link href='mailto:tclevenger@assurance.com'>Todd Clevenger</Link>
          </li>
          <li>
            <strong>Frontend:</strong> <Link href='mailto:chung@assurance.com'>Chung Han</Link>
          </li>
          <li>
            <strong>Backend:</strong> <Link href='mailto:dalee@assurance.com'>David Lee</Link>,{' '}
            <Link href='mailto:esmilde@assurance.com'>Edward Smilde</Link>
          </li>
          <li>
            <strong>Product:</strong>{' '}
            <Link href='mailto:jefflindquist@assurance.com'>Jeff Lindquist</Link>,{' '}
            <Link href='mailto:chris.sjang@assurance.com'>Chris Jang</Link>
          </li>
        </ul>
      </Box>

      <Box my={3}>
        Join us in <img src='/images/slack-logo.png' alt='slack' />:{' '}
        <Link href='https://assuranceiq.slack.com/archives/C03EYDX2FKP' target='_blank'>
          #omnichannel
        </Link>
      </Box>
    </div>
  )
}
