import { Action, action, createStore as createStoreEasyPeasy, persist } from 'easy-peasy'
import _ from 'lodash'

import { EventType, SortOrder } from '../enums'

/** App Model **/

interface AppModel {
  accessToken: string | null
  setAccessToken: Action<AppModel, string | null>
}

const appModel: AppModel = {
  accessToken: sessionStorage.getItem('accessToken'),
  setAccessToken: action((state, accessToken) => {
    if (!accessToken) {
      sessionStorage.removeItem('accessToken')
    } else {
      sessionStorage.setItem('accessToken', accessToken)
    }
    state.accessToken = accessToken
  })
}

/** Settings Model (Note: These are persisted via localStorage) **/

interface SettingsModel {
  eventFilter: EventType[]
  setEventFilter: Action<SettingsModel, EventType[]>
  sortOrder: SortOrder
  setSortOrder: Action<SettingsModel, SortOrder>
}

const settingsModel: SettingsModel = {
  eventFilter: [],
  setEventFilter: action((state, eventFilter) => {
    state.eventFilter = eventFilter
  }),
  sortOrder: SortOrder.RECENT_FIRST,
  setSortOrder: action((state, sortOrder) => {
    state.sortOrder = sortOrder
  })
}

/** Store Model (final assembly) **/

export interface StoreModel {
  app: AppModel
  settings: SettingsModel
}

export const createStore = function (defaults?: StoreModel) {
  const model = {
    app: appModel,
    settings: persist(settingsModel, { storage: 'localStorage' })
  }

  if (defaults) {
    _.merge(model.app, defaults.app)
    _.merge(model.settings, defaults.settings)
  }

  return createStoreEasyPeasy<StoreModel>(model)
}
