import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import _ from 'lodash'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { GRAPHQL_ENDPOINT } from '../utils/environment-variables'

export function createApolloClient() {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const statusCode = _.get(networkError, 'statusCode')
    if (statusCode) {
      console.error(`Network error status ${statusCode} received`)
    }
    if (statusCode === 401) {
      // JWT token expired or invalid
      return window.location.reload()
    } else if (statusCode === 403) {
      // No permission to access the app
      return alert(
        '✋ You do not have access to Journey Hub.\n\nPlease contact IT support request the following CRM Role:\n"journey_hub.viewer"'
      )
    }
    if (!_.isEmpty(networkError)) {
      console.error('Network error!', networkError)
      alert('Network error!\n' + networkError)
    }
    if (!_.isEmpty(graphQLErrors)) {
      console.error('GraphQL error!', graphQLErrors)
      alert('GraphQL error!\n' + JSON.stringify(graphQLErrors, null, 2))
    }
  })

  // needed for GraphQL requests to appear in LogRocket
  // https://docs.logrocket.com/docs/graphql#apollo-client-with-graphql
  const fetcher = (...args) => {
    // @ts-ignore
    return window.fetch(...args)
  }

  const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
    fetch: fetcher
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('accessToken')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...(token && { Authorization: `JWT ${token}` })
      }
    }
  })

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache()
  })
}
