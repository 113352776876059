import { gql } from '@apollo/client'

export const PIPELINE_APPOINTMENT_FRAGMENT = gql`
  fragment PipelineAppointmentFragment on PipelineAppointment {
    id
    createdAt
    updatedAt
    about
    duration
    identifier
    startTime
    endTime
    dateCreated
    dateDeleted
    dateModified
    noteCount
    opportunity {
      id
      identifier
    }
    sendSmsConfirmation
  }
`
