import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vG1Xmpjf
 * GQL Schema: https://github.com/assuranceiq/journey-client-backend/blob/main/client_backend/graphql/schema.graphql
 */
export const AGENT_SUMMARY_QUERY = gql`
  query AgentSummary($joinAgentId: String!) {
    agent(filter: { joinAgentId: $joinAgentId }) {
      email
      givenName
      familyName
      telephones
      createdAt
      updatedAt
      joinAgentId
    }
    agentShopperSummary(joinAgentId: $joinAgentId, first: 100) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        numPages
      }
      edges {
        node {
          leadId
          leadType
          lastCommunicationAction
          lastCommunicatedAt
        }
        cursor
      }
    }
  }
`
