import { gql } from '@apollo/client'

import { COMMUNICATE_ACTION_FRAGMENT } from './communicate-action-fragment'
import { JOURNEY_STATUS_FRAGMENT } from './journey-status-fragment'

export const JOURNEY_FRAGMENT = gql`
  fragment JourneyFragment on Journey {
    id
    identifier
    createdAt
    updatedAt
    journeyStatus {
      ...JourneyStatusFragment
    }
    isPartOf {
      id
      name
    }
    communicateActions(limit: $actions) {
      ...CommunicateActionFragment
    }
    callCampaignSummaries {
      id
      createdAt
      leadId
      leadType
      numberOfCalls
      policySold
      wasTransferred
      disambiguatingDescription
    }
  }
  ${JOURNEY_STATUS_FRAGMENT}
  ${COMMUNICATE_ACTION_FRAGMENT}
`
