import { gql } from '@apollo/client'

import { COMMUNICATE_ACTION_FRAGMENT } from '../fragments/communicate-action-fragment'
import { JOURNEY_STATUS_FRAGMENT } from '../fragments/journey-status-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/ds83qFvn
 * GQL Schema: https://github.com/assuranceiq/journey-client-backend/blob/main/client_backend/graphql/schema.graphql
 */
export const JOURNEY_HISTORY_QUERY = gql`
  query journeyHistory(
    $journeyName: String!
    $limit: Int
    $after: ID
    $eventTypeFilter: [EventTypes!]
    $sortOrder: SortOrder
  ) {
    journeyHistory(
      journeyName: $journeyName
      limit: $limit
      after: $after
      eventTypeFilter: $eventTypeFilter
      sortOrder: $sortOrder
    ) {
      events {
        __typename
        ...CommunicateActionFragment
        ...JourneyStatusFragment
      }
    }
  }
  ${JOURNEY_STATUS_FRAGMENT}
  ${COMMUNICATE_ACTION_FRAGMENT}
`
