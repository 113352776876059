import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, Paper } from '@mui/material'

import _ from 'lodash'

import { EventType } from '../../enums'
import LogRocket from '../../utils/logrocket'

import styles from './JourneyHistoryFilters.module.scss'

interface JourneyHistoryFiltersProps {
  eventTypes?: EventType[]
  onChange?: (eventTypes: EventType[]) => void
}

export function JourneyHistoryFilters(props: JourneyHistoryFiltersProps) {
  const { eventTypes = [EventType.SMS], onChange = _.noop } = props

  const handleChange = (eventType: EventType, checked: boolean) => {
    LogRocket.track('FilterEventTypes')
    if (checked) {
      onChange(_.uniq([...eventTypes, eventType]))
    } else {
      onChange(_.without(eventTypes, eventType))
    }
  }

  return (
    <Box
      className={`animate__animated animate__slideInLeft ${styles.filters}`}
      sx={{ minWidth: '17rem' }}
    >
      <Paper className={styles.paper} variant='outlined'>
        <FormHelperText>FILTER BY EVENT TYPE</FormHelperText>
        <FormGroup sx={{ mt: 1 }}>
          <FormControlLabel
            label='SMS'
            control={
              <Checkbox
                checked={eventTypes.includes(EventType.SMS)}
                onChange={e => handleChange(EventType.SMS, e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label='Call'
            control={
              <Checkbox
                checked={eventTypes.includes(EventType.TELEPHONE)}
                onChange={e => handleChange(EventType.TELEPHONE, e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label='Journey Start'
            control={
              <Checkbox
                checked={eventTypes.includes(EventType.JOURNEY_START)}
                onChange={e => handleChange(EventType.JOURNEY_START, e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label='Journey Complete'
            control={
              <Checkbox
                checked={eventTypes.includes(EventType.JOURNEY_END)}
                onChange={e => handleChange(EventType.JOURNEY_END, e.target.checked)}
              />
            }
          />
        </FormGroup>
      </Paper>
      <FormHelperText className={styles.helper}>Journey Hub stores 2 weeks of data</FormHelperText>
    </Box>
  )
}
