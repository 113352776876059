import { useSearchParams } from 'react-router-dom'

import { Alert, List, ListSubheader } from '@mui/material'

import { Loading } from '@assuranceiq/react-components'
import _ from 'lodash'
import clsx from 'clsx'
import { useQuery } from '@apollo/client'

import { FooterCard } from '../components/FooterCard'
import { IntroCard } from '../components/IntroCard'
import { JOURNEY_TYPES_QUERY } from '../graphql/queries/journey-types'
import { JourneyListItem } from '../components/JourneyListItem'
import JourneyQuery from '../navigation/JourneyQuery'
import type { JourneyType, Person } from '../types'
import { ListItemSkeleton } from '../components/ListItemSkeleton'
import { Profile } from '../components/Profile'
import { createFilter } from '../utils/search-query'

import styles from './Home.module.scss'

export function Home() {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('q') || getSearchParam('q') || ''

  console.log('Rendering Home page')
  if (!query) return <HomeContent />
  return <SearchResult query={query} />
}

function HomeContent() {
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <IntroCard />
        <FooterCard />
      </div>
      <div className={clsx(styles.right, 'animate__animated animate__slideInRight')}>
        <JourneyListView />
      </div>
    </div>
  )
}

function JourneyListView() {
  const { loading, error, data } = useQuery(JOURNEY_TYPES_QUERY)

  if (loading) return <ListItemSkeleton repeat={25} />
  if (error) console.error('Error while querying journey types!', error)

  let journeyTypes: JourneyType[] = _.get(data, 'journeyTypes', [])
  journeyTypes = _.sortBy(journeyTypes, 'createdAt').reverse()

  return (
    <List dense>
      <ListSubheader>Active Journeys</ListSubheader>
      {journeyTypes.map(journeyType => (
        <JourneyListItem key={journeyType.id} journeyType={journeyType} />
      ))}
    </List>
  )
}

function SearchResult({ query }) {
  const filter = createFilter(query)
  const isAgentFilter = !!filter.joinAgentId
  return (
    <>
      <Alert severity='success' sx={{ mb: 2 }}>
        Showing results for: {JSON.stringify(filter, null, 2)}
      </Alert>

      <JourneyQuery filter={filter} query={query}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) console.error('Error while querying shoppers!', error)

          const shopper = _.first(data?.shoppers) as Person
          if (shopper || data?.agent) {
            return <Profile person={shopper || data.agent} rawData={data} />
          }

          return <>No matching {isAgentFilter ? 'agent' : 'shopper'} found!</>
        }}
      </JourneyQuery>
    </>
  )
}

/**
 * Good ol' vanilla JS way to get a query param from the URL.
 * We fall back to this solution in case the `useSearchParams` hook isn't ready to use
 * (i.e. when the app first loads from auth redirect)
 */
function getSearchParam(key: string) {
  const url = new URL(window.location.href)
  return url.searchParams.get(key)
}
