import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material'

interface BreadcrumbsProps {
  root: string
  title: string
  subtitle?: string | React.ReactNode
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { root, title, subtitle } = props
  return (
    <div role='presentation'>
      <MuiBreadcrumbs separator='›' aria-label='breadcrumb'>
        <Link component={RouterLink} underline='hover' color='inherit' to='..' relative='path'>
          {root}
        </Link>
        <Typography
          color='text.primary'
          display='flex'
          alignItems='center'
          component='div'
          sx={{ lineHeight: '2.25rem' }}
        >
          <strong>{title}</strong>
          {subtitle && <Box ml={1}>{subtitle}</Box>}
        </Typography>
      </MuiBreadcrumbs>
    </div>
  )
}
