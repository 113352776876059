import React from 'react'

import { Alert, AlertTitle, Typography } from '@mui/material'

import _ from 'lodash'

import LogRocket from '../../utils/logrocket'
import { ServerError } from '../../types'

import styles from './Error.module.scss'

interface ErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: string
  message?: string
  errors?: ServerError[]
  size?: 'small' | 'large'
}

export function Error(props: ErrorProps) {
  const { header = 'Oops, something went wrong!', errors = [], size = 'large' } = props

  let message = props.message
  if (errors.length === 0 && !props.message) {
    message = 'Please try again later.'
  }

  LogRocket.track('Error', { header, message })

  return (
    <div className={size === 'small' ? styles.small : styles.large}>
      {errors.length === 1 ? (
        <Alert severity='error' className={styles.error}>
          <AlertTitle>{header}</AlertTitle>
          {_.isEmpty(errors[0].field) ? '' : `${errors[0].field} - `}
          {errors[0].message || errors[0].code}
        </Alert>
      ) : (
        <Typography variant='h4' component='h4' gutterBottom className={styles.header}>
          {header}
        </Typography>
      )}

      {errors.length > 1 &&
        errors.map((err, ii) => (
          <Alert key={`${err.field}-${err.message}${ii}`} severity='error' className={styles.error}>
            {_.isEmpty(err.field) ? '' : `${err.field} - `}
            {err.message}
          </Alert>
        ))}

      <Typography variant='h5' component='h5' gutterBottom className={styles.message}>
        {message}
      </Typography>
    </div>
  )
}
