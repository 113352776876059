import React from 'react'
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'

import { AppBar, Box, Button, IconButton, Toolbar, Tooltip } from '@mui/material'
import { LiveTv, Logout } from '@mui/icons-material'

import _ from 'lodash'
import clsx from 'clsx'
import { useAuth0 } from '@auth0/auth0-react'

import { SearchBar } from '../components/SearchBar'
import { createQueryUrl } from '../utils/search-query'

import styles from './Layout.module.scss'

interface LayoutProps {
  children: React.ReactNode
}

export function Layout({ children }: LayoutProps) {
  console.log('Rendering Layout')
  return (
    <div className={styles.root}>
      <Header />
      <main>{children}</main>
    </div>
  )
}

function Header() {
  const { user, logout } = useAuth0()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const location = window.document.location
  const username = user?.given_name || _.upperFirst(user?.nickname) || user?.name || user?.email

  const doLogout = () => {
    if (window.confirm('You are about to log out. Proceed?')) {
      logout({ returnTo: location.origin })
    }
  }

  return (
    <AppBar
      position='relative'
      color='default'
      className={clsx(styles.appBar, 'animate__animated animate__fadeIn')}
      style={{
        // inline here to reduce FOUC
        // https://en.wikipedia.org/wiki/Flash_of_unstyled_content
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fafafa'
      }}
    >
      <RouterLink className={styles.logo} to='/'>
        <img src='/images/assurance-logo.svg' alt='Assurance logo' />
        <span>Journey Hub</span>
      </RouterLink>
      <Toolbar className={styles.toolbar}>
        <Box>
          <SearchBar
            defaultQuery={searchParams.get('q') || ''}
            onSearch={q => navigate(createQueryUrl(q))}
          />
          <Button
            variant='text'
            color='primary'
            startIcon={<LiveTv />}
            component={RouterLink}
            to='/live-feed'
            sx={{ ml: 2.5, display: { xs: 'none', md: 'inline-flex' } }}
          >
            Live Feed
          </Button>
        </Box>
        <Box display='flex' alignItems='center' className='animate__animated animate__slideInRight'>
          <Box className={styles.username} mr={1}>
            Welcome, {username}
          </Box>
          <Tooltip title='Logout' arrow>
            <IconButton onClick={doLogout} color='info'>
              <Logout />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
