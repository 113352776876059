import { gql } from '@apollo/client'

export const PIPELINE_OPPORTUNITY_FRAGMENT = gql`
  fragment PipelineOpportunityFragment on PipelineOpportunity {
    id
    createdAt
    updatedAt
    identifier
    agentAssignedOpportunityQuality
    agentAssignedSalesStage
    dateCreated
    dateDeleted
    dateModified
    deltaContactId
    enrollmentPeriod
    noteCount
    opportunitySource
    products
  }
`
