import {
  Chat,
  ChatBubbleOutline,
  CommentsDisabled,
  Mouse,
  Sms,
  SmsFailed
} from '@mui/icons-material'

import clsx from 'clsx'

import { ActivityCard } from '../ActivityCard'
import { AgentLink } from '../AgentLink'
import type { CommunicateAction } from '../../types'
import { UnhandledActionStatusError } from '../UnhandledActionStatusError'
import { getCommonActivityCardProps } from '../../utils/timeline'

import styles from './ChatMessage.module.scss'

interface ChatMessageProps {
  action: CommunicateAction
  startTime: string // note: this prop is required for Timeline sorting to work
}

export function ChatMessage(props: ChatMessageProps) {
  const { action, startTime = '' } = props

  const commonProps = getCommonActivityCardProps(action, startTime)

  switch (action.actionStatus) {
    case 'SMS Delivered':
      return (
        <ActivityCard {...commonProps} icon={Chat}>
          Received an SMS message
          {action.agent && (
            <>
              {' '}
              from{' '}
              <AgentLink
                agent={action.agent}
                disambiguatingDescription={action.disambiguatingDescription}
              />
            </>
          )}
          <div className={clsx(styles.smsMessage, styles.received)}>{action.object?.text}</div>
        </ActivityCard>
      )
    case 'SMS Received':
      if (action.object?.text) {
        return (
          <ActivityCard {...commonProps} icon={Sms}>
            Sent an SMS message
            {action.recipient && (
              <>
                {' '}
                to{' '}
                <AgentLink
                  agent={action.recipient}
                  disambiguatingDescription={action.disambiguatingDescription}
                />
              </>
            )}
            <div className={styles.smsMessage}>{action.object?.text}</div>
          </ActivityCard>
        )
      } else {
        return (
          <ActivityCard {...commonProps} icon={Sms}>
            Sent an MMS message
            {action.recipient && (
              <>
                {' '}
                to{' '}
                <AgentLink
                  agent={action.recipient}
                  disambiguatingDescription={action.disambiguatingDescription}
                />
              </>
            )}
            <div className={styles.smsMessage}>
              <em>multimedia attachment</em>
            </div>
          </ActivityCard>
        )
      }
    case 'SMS Power Dial Replied':
      return (
        <ActivityCard {...commonProps} icon={Sms}>
          Replied to Power Dial
          <div className={styles.smsMessage}>
            {action.object?.text ? action.object.text : <em>multimedia attachment</em>}
          </div>
        </ActivityCard>
      )
    case 'SMS DNC':
      return (
        <ActivityCard {...commonProps} icon={CommentsDisabled}>
          Opted out of SMS messages
          {action.object?.text && <div className={styles.smsMessage}>{action.object?.text}</div>}
        </ActivityCard>
      )
    case 'SMS Clicked':
      return (
        <ActivityCard {...commonProps} icon={Mouse}>
          Clicked a link in SMS
        </ActivityCard>
      )
    // not for engagement
    case 'SMS Sent':
      return (
        <ActivityCard {...commonProps} icon={ChatBubbleOutline} variant='info'>
          Message
          {action.agent && (
            <>
              {' '}
              from{' '}
              <AgentLink
                agent={action.agent}
                disambiguatingDescription={action.disambiguatingDescription}
              />
            </>
          )}{' '}
          was sent. Delivery not confirmed by carrier.
          {action.object?.text && (
            <div className={clsx(styles.smsMessage, styles.system)}>{action.object?.text}</div>
          )}
        </ActivityCard>
      )
    // not for engagement
    case 'SMS Undelivered':
    case 'SMS Failed':
      return (
        <ActivityCard {...commonProps} icon={SmsFailed} variant='warning'>
          <b>{action.actionStatus}</b>: Message
          {action.agent && (
            <>
              {' '}
              from{' '}
              <AgentLink
                agent={action.agent}
                disambiguatingDescription={action.disambiguatingDescription}
              />
            </>
          )}{' '}
          could not be delivered
          <div className={clsx(styles.smsMessage, styles.system)}>{action.object?.text}</div>
        </ActivityCard>
      )
    case 'SMS Not Sent':
      return (
        <ActivityCard {...commonProps} icon={SmsFailed} variant='warning'>
          <b>{action.actionStatus}</b>: Message
          {action.agent && (
            <>
              {' '}
              from{' '}
              <AgentLink
                agent={action.agent}
                disambiguatingDescription={action.disambiguatingDescription}
              />
            </>
          )}{' '}
          not sent for campaign <em>{action.name}</em>
        </ActivityCard>
      )
    default:
      return <UnhandledActionStatusError action={action} />
  }
}
