import React, { useEffect, useState } from 'react'

import { Actions, useStoreActions } from 'easy-peasy'
import { Loading, useDataDogRUM } from '@assuranceiq/react-components'
import type { User } from '@auth0/auth0-spa-js'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'

import { Error } from '../components/Error'
import LogRocket from '../utils/logrocket'
import type { StoreModel } from '../utils/store'
import { findWindowLocationParameter } from '../utils/url'

interface AuthenticateProps {
  children: React.ReactNode
}

function Authenticate({ children }: AuthenticateProps) {
  const { error, getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  const { addRumGlobalContext } = useDataDogRUM()
  const setAccessToken = useStoreActions(
    (actions: Actions<StoreModel>) => actions.app.setAccessToken
  )
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const addTokenCheckListener = (tokenExp?: number) => {
    if (!tokenExp) return
    // every time the browser tab gains focus, check if token has expired
    // if expired, refresh the page so that Auth0 provider can do its thing
    window.onfocus = function () {
      if (Date.now() >= tokenExp * 1000) {
        console.log('Expired token detected. Reloading app...')
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    getIdTokenClaims()
      .then(res => {
        addTokenCheckListener(res?.exp)
        addRumGlobalContext('user', {
          id: res?.sub,
          name: res?.name || res?.email,
          email: res?.email
        })
        LogRocket.init()
        LogRocket.identify(res as User)
        return getAccessTokenSilently()
      })
      .then(accessToken => {
        setAccessToken(accessToken)
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    console.error(error)
    return <Error />
  }

  if (isLoading) {
    return <Loading fullscreen />
  }

  console.log('Authenticated')
  return <>{children}</>
}

export default withAuthenticationRequired(Authenticate, {
  returnTo: window.location.href,
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: function HOC() {
    const { logout } = useAuth0()
    // [OMNI-1258] Auth0 may redirect to us with something like this to indicate auth0 errored out:
    // http://localhost:3000/?error=unauthorized&error_description=User%20not%20allowed
    const error = findWindowLocationParameter('error')
    if (error) {
      // Logout so that user goes back to the login prompt instead of infinite looping
      logout({ returnTo: window.location.origin })

      const errorDesc = findWindowLocationParameter('error_description')
      console.error(`Journey Hub authentication encountered error: ${error} - ${errorDesc}`)
      alert(`✋ Journey Hub authentication encountered error: ${error} - ${errorDesc}`)
    }
    // show a loading spinner while the user waits to be redirected to the login page
    return <Loading fullscreen />
  }
})
