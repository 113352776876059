import React from 'react'

import { Box, Typography } from '@mui/material'
import { Circle } from '@mui/icons-material'

import clsx from 'clsx'
import dayjs from 'dayjs'

import { JourneyNameChip } from '../JourneyNameChip'
import { LeadIdChip } from '../LeadIdChip'
import { sanitizeISOString } from '../../utils/date'

import styles from './ActivityCard.module.scss'

interface ActivityCardProps {
  startTime: string // ISO date string
  endTime?: string | undefined | null // ISO date string (optional)
  icon?: React.ReactNode
  variant?: 'default' | 'error' | 'warning' | 'info'
  journeyName?: string
  leadId?: string
  leadType?: string
  routeLeadId?: boolean // route to shopper page in app instead of opening CRM admin page
  children: React.ReactNode
}

export function ActivityCard(props: ActivityCardProps) {
  const {
    startTime,
    endTime,
    icon = null,
    variant = 'default',
    journeyName,
    leadId,
    leadType,
    routeLeadId = false,
    children
  } = props

  const Icon = props => {
    const Icon = props.icon
    return props.icon ? <Icon fontSize='small' /> : <Circle fontSize='small' />
  }

  return (
    <Box
      display='flex'
      className={clsx(
        styles.root,
        variant === 'error' && styles.error,
        variant === 'warning' && styles.warning,
        variant === 'info' && styles.info
      )}
    >
      <Box mr={1}>
        <Icon icon={icon} />
      </Box>
      <Box>
        <Typography className={styles.body} variant='body2' display='block' component='div'>
          {children}
        </Typography>
        <Typography variant='caption'>{displayDateTime(startTime, endTime)}</Typography>
        {journeyName && <JourneyNameChip name={journeyName} />}
        {leadId && <LeadIdChip leadId={leadId} leadType={leadType} routeLeadId={routeLeadId} />}
      </Box>
    </Box>
  )
}

function displayDateTime(startTime, endTime) {
  startTime = sanitizeISOString(startTime)
  let timeLabel = dayjs(startTime).format('M/D/YYYY @ h:mma')
  if (endTime) {
    endTime = sanitizeISOString(endTime)
    const startDate = dayjs(startTime).format('M/D/YYYY')
    const endDate = dayjs(endTime).format('M/D/YYYY')
    if (startDate !== endDate) {
      timeLabel += ` - ${dayjs(endTime).format('M/D/YYYY @ h:mma')}`
    } else {
      const diffSeconds = dayjs(endTime).diff(startTime, 'second')
      if (diffSeconds < 60) return timeLabel
      timeLabel += ` - ${dayjs(endTime).format('h:mma')}`
    }
  }
  return timeLabel
}
