import { Link as RouterLink } from 'react-router-dom'

import { Chip, Link, Tooltip } from '@mui/material'
import { KeyboardDoubleArrowRight } from '@mui/icons-material'

import { LoiIcon } from '../LoiIcon'
import { createCrmLeadUrl } from '../../utils/url'
import { createShopperUrl } from '../../utils/search-query'

import styles from './LeadIdChip.module.scss'

interface LeadIdChipProps {
  leadId: string
  leadType?: string // LOI ("medicare", "health", "life", "auto", "home", "mortgage")
  routeLeadId?: boolean // route to shopper page in app instead of opening CRM admin page
}

export function LeadIdChip(props: LeadIdChipProps) {
  const { leadId, leadType, routeLeadId = false } = props

  let tooltip = 'Lead ID'
  if (leadType) tooltip += ` (${leadType})`

  let routeIcon = <></>
  if (routeLeadId)
    routeIcon = (
      <KeyboardDoubleArrowRight fontSize='small' color='primary' sx={{ mb: -0.75, mr: -0.9 }} />
    )

  const label = (
    <>
      {leadType && <LoiIcon loi={leadType} />}
      {leadId}
      {routeIcon}
    </>
  )

  const linkProps = {
    component: Link,
    href: createCrmLeadUrl(leadId),
    target: '_blank'
  }
  const routerProps = {
    component: RouterLink,
    to: createShopperUrl({ leads: [{ leadId }] })
  }

  return (
    <Tooltip title={tooltip} placement='right' arrow>
      <Chip
        className={styles.root}
        label={label}
        size='small'
        color='default'
        variant='outlined'
        onClick={e => {
          e.stopPropagation()
        }}
        {...(routeLeadId ? routerProps : linkProps)}
      />
    </Tooltip>
  )
}
