import { Link as RouterLink } from 'react-router-dom'

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Send } from '@mui/icons-material'

import type { JourneyType } from '../../types'
import { humanizeTimeDiff } from '../../utils/date'

interface JourneyListItemProps {
  journeyType: JourneyType
}

export function JourneyListItem(props: JourneyListItemProps) {
  const { journeyType } = props
  const timeDiff = humanizeTimeDiff(journeyType.createdAt || '').toLowerCase()
  return (
    <ListItem component={RouterLink} to={`/journeys/${journeyType.name}`} disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        <ListItemText
          primary={journeyType.name}
          secondary={timeDiff ? `Started ${timeDiff}` : undefined}
        />
      </ListItemButton>
    </ListItem>
  )
}
