import { gql } from '@apollo/client'

export const JOURNEY_STATUS_FRAGMENT = gql`
  fragment JourneyStatusFragment on JourneyStatus {
    id
    createdAt
    updatedAt
    status
    code
    description
    leadId
    leadType
  }
`
