import { gql } from '@apollo/client'

export const COMMUNICATE_ACTION_MESSAGE_FRAGMENT = gql`
  fragment CommunicateActionMessageFragment on CommunicateActionMessage {
    id
    createdAt
    updatedAt
    about
    duration
    headline
    identifier
    text
    url
  }
`
