export enum EmailActionType {
  EMAIL_OPEN = 'EMAIL_OPEN',
  EMAIL_CLICK = 'EMAIL_CLICK',
  EMAIL_UNSUBSCRIBE = 'EMAIL_UNSUBSCRIBE',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED', // not for an engagement email
  EMAIL_SENT = 'EMAIL_SENT', // not for an engagement email
  EMAIL_NOT_SENT = 'EMAIL_NOT_SENT' // not for an engagement email
}

// for CommunicateAction
export enum Instrument {
  EMAIL = 'email',
  TELEPHONE = 'telephone',
  SMS = 'sms',
  PIPELINE = 'pipeline'
}

export enum JourneyStatusType {
  STARTED = 'started',
  SUCCEEDED = 'succeeded', // completed
  FAILED = 'failed',
  PROGRESS = 'progress'
}

// for JourneyHistoryFilters
export enum EventType {
  TELEPHONE = 'Telephone',
  SMS = 'SMS',
  JOURNEY_START = 'JourneyStart',
  JOURNEY_END = 'JourneyEnd'
}

export enum DncEventType {
  DNC_ADDED = 'DNC Added',
  DNC_OVERTURNED = 'DNC Overturned'
}

export enum SortOrder {
  RECENT_FIRST = 'RecentFirst',
  OLDEST_FIRST = 'OldestFirst'
}
