/**
 * Returns a pluralized form of the given word based on the given occurrences.
 * ex) pluralize(2, "item") -> "2 items"
 * ex) pluralize(5, "city") -> "5 cities"
 * ex) pluralize(1, "call") -> "1 call"
 * ex) pluralize(0, "call") -> "0 calls"
 * ex) pluralize(undefined, "call") -> "0 calls"
 */
export function pluralize(occurrences: number | undefined, word: string) {
  if (occurrences === 1) {
    return `${occurrences} ${word}`
  }
  if (word.endsWith('y')) {
    word = word.substring(0, word.length - 1) + 'ie'
  }
  return `${occurrences == null ? '0' : occurrences} ${word}s`
}
