import { FormGroup, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import _ from 'lodash'

import { SortOrder } from '../../enums'

import styles from './JourneyHistorySortOptions.module.scss'

interface JourneyHistorySortOptionsProps {
  sortOrder?: SortOrder
  onChange?: (sortOrder: SortOrder) => void
}

export function JourneyHistorySortOptions(props: JourneyHistorySortOptionsProps) {
  const { sortOrder = SortOrder.RECENT_FIRST, onChange = _.noop } = props

  const handleChange = (event: SelectChangeEvent<SortOrder>) => {
    onChange(event.target.value)
  }

  return (
    <FormGroup className={styles.controls}>
      <Select value={sortOrder} onChange={handleChange} size={'small'}>
        <MenuItem value={SortOrder.RECENT_FIRST}>Show recent journeys first</MenuItem>
        <MenuItem value={SortOrder.OLDEST_FIRST}>Show oldest journeys first</MenuItem>
      </Select>
    </FormGroup>
  )
}
