/**
 * Set of helper functions for arranging, filtering, and manufacturing different forms of
 * ActivityCard instances, to be used in various Timeline views in the app.
 */

import React from 'react'

import {
  Block,
  CalendarMonth,
  Cancel,
  CancelScheduleSend,
  Drafts,
  MarkEmailUnread,
  Mouse,
  Phone,
  Send,
  Undo,
  Unsubscribe,
  Voicemail,
  Web
} from '@mui/icons-material'
import { Link, Typography } from '@mui/material'

import _ from 'lodash'
import dayjs from 'dayjs'

import { ActivityCard } from '../components/ActivityCard'
import { AgentLink } from '../components/AgentLink'
import { ChatMessage } from '../components/ChatMessage'
import type { CommunicateAction, Journey, JourneyStatus, QuestionAndAnswers } from '../types'
import { DncEventType, EmailActionType, Instrument } from '../enums'
import { JourneyStatusCard } from '../components/ActivityCard/JourneyStatusCard'
import { TimeDivider } from '../components/TimeDivider'
import { UnhandledActionStatusError } from '../components/UnhandledActionStatusError'
import { humanizeDuration } from './date'
import { pluralize } from './pluralize'

const DIVIDER_MIN_DAYS = 3 // insert a TimeDivider if dates are this many days apart

export function getEmailActions(communicateActions: CommunicateAction[]) {
  const displayMetadata = action =>
    action.object?.['headline'] ? (
      <>
        (subject: <b>{action.object['headline']}</b>)
      </>
    ) : null
  return _.chain(communicateActions)
    .filter({ instrument: Instrument.EMAIL })
    .map(o => {
      const props = getCommonActivityCardProps(o)
      switch (o.actionStatus) {
        case EmailActionType.EMAIL_OPEN:
          return (
            <ActivityCard {...props} icon={Drafts}>
              Opened email {displayMetadata(o)}
            </ActivityCard>
          )
        case EmailActionType.EMAIL_CLICK:
          return (
            <ActivityCard {...props} icon={Mouse}>
              Clicked a link in email {displayMetadata(o)}
            </ActivityCard>
          )
        case EmailActionType.EMAIL_UNSUBSCRIBE:
          return (
            <ActivityCard {...props} icon={Unsubscribe}>
              Unsubscribed from email {displayMetadata(o)}
            </ActivityCard>
          )
        // not for engagement (but we fake it as engagement)
        case EmailActionType.EMAIL_SENT:
          return (
            <ActivityCard {...props} icon={MarkEmailUnread}>
              Received email {displayMetadata(o)}
            </ActivityCard>
          )
        // not for engagement
        case EmailActionType.EMAIL_BOUNCED:
          return (
            <ActivityCard {...props} icon={Cancel} variant='warning'>
              Email bounced {displayMetadata(o)}
            </ActivityCard>
          )
        // not for engagement
        case EmailActionType.EMAIL_NOT_SENT:
          return (
            <ActivityCard {...props} icon={CancelScheduleSend} variant='warning'>
              Email not sent for campaign {o.name} {displayMetadata(o)}
            </ActivityCard>
          )
        default:
          return <UnhandledActionStatusError action={o} />
      }
    })
    .value()
}

export function getMissedCallSummaries(communicateActions: CommunicateAction[]) {
  return _.chain(communicateActions)
    .filter({ instrument: Instrument.TELEPHONE })
    .filter(o => o.error !== undefined && o.error !== null)
    .map(o => {
      const props = getCommonActivityCardProps(o)
      return (
        <ActivityCard {...props} key={o.id} endTime={o.endTime} icon={Phone}>
          Missed {pluralize(o.missedCallCount, 'call')}
        </ActivityCard>
      )
    })
    .value()
}

export function getTelephoneActions(communicateActions: CommunicateAction[], isShopper?) {
  return _.chain(communicateActions)
    .filter({ instrument: Instrument.TELEPHONE })
    .filter(o => !o.error)
    .map(o => {
      const duration = humanizeDuration(o.object?.duration || '')
      const props = getCommonActivityCardProps(o)
      const url = o.object?.url
      const isInbound = o.agent && (!isShopper || isShopper(o.agent))
      const callStats = (
        <>
          {' ('}
          {duration && duration + ', '}
          <em>{o.name}</em>
          {')'}
          {isInbound && o.recipient && (
            <>
              {' to '}
              <AgentLink
                agent={o.recipient}
                disambiguatingDescription={o.disambiguatingDescription}
              />
            </>
          )}
          {!isInbound && o.agent && (
            <>
              {' from '}
              <AgentLink agent={o.agent} disambiguatingDescription={o.disambiguatingDescription} />
            </>
          )}
        </>
      )
      switch (o.actionStatus) {
        case 'Voice Mail':
          return (
            <ActivityCard {...props} icon={Voicemail}>
              {isInbound ? 'Left' : 'Received'} a{' '}
              {url ? (
                <Link href={url} target='_blank'>
                  voicemail
                </Link>
              ) : (
                'voicemail'
              )}
              {callStats}
            </ActivityCard>
          )
        default:
          return (
            <ActivityCard {...props} endTime={o.endTime} icon={Phone}>
              {isInbound ? 'Made' : 'Took'} a{' '}
              {url ? (
                <Link href={url} target='_blank'>
                  call
                </Link>
              ) : (
                'call'
              )}
              {callStats}. Indicated <b>{o.actionStatus}</b>
            </ActivityCard>
          )
      }
    })
    .value()
}

export function getSmsActions(communicateActions: CommunicateAction[]) {
  return _.chain(communicateActions)
    .filter({ instrument: Instrument.SMS })
    .map(o => <ChatMessage key={o.id} action={o} startTime={getStartTimeForAction(o)} />)
    .value()
}

export function getDncActions(communicateActions: CommunicateAction[]) {
  const DNC_EVENT_TYPES = Object.values<string>(DncEventType)
  return _.chain(communicateActions)
    .filter(o => DNC_EVENT_TYPES.includes(o.actionStatus))
    .map(o => {
      return o.actionStatus === DncEventType.DNC_ADDED ? (
        <ActivityCard
          key={o.identifier}
          startTime={getStartTimeForAction(o)}
          variant={'error'}
          icon={Block}
          leadId={o.leadId}
          leadType={o.leadType}
        >
          Added to <b>{o.about?.toUpperCase()}</b> DNC list (campaign: <b>{o.name}</b>)
          {o.agent && (
            <span>
              {' '}
              by{' '}
              <AgentLink agent={o.agent} disambiguatingDescription={o.disambiguatingDescription} />
            </span>
          )}
        </ActivityCard>
      ) : (
        <ActivityCard
          key={o.identifier}
          startTime={getStartTimeForAction(o)}
          icon={Undo}
          variant={'info'}
          leadId={o.leadId}
          leadType={o.leadType}
        >
          {o.about?.includes('internal') ? (
            <span>
              <b>INTERNAL</b> DNC overridden (campaign: <b>{o.name}</b>)
            </span>
          ) : (
            'Added to DNC whitelisted number'
          )}
        </ActivityCard>
      )
    })
    .value()
}

export function getJourneyEventStatuses(journeys: Journey[], leadIdsToShow) {
  return _.flatMap(journeys, journey => {
    let statuses = journey.journeyStatus || []
    // filter by Lead IDs, when applicable
    if (leadIdsToShow != null) {
      statuses = _.filter(
        statuses,
        o => !o.leadId || leadIdsToShow.includes(o.leadId)
      ) as JourneyStatus[]
    }
    return _.map(statuses, status => (
      <JourneyStatusCard
        key={status.createdAt + status.status}
        status={status}
        journeyName={journey.isPartOf?.name || 'unknown'}
        startTime={status.createdAt || ''}
      />
    ))
  })
}

export function getJourneyForms(journeys: Journey[]) {
  return _.flatMap(journeys, journey => [
    ..._.flatMap(journey.forms, form => [
      <ActivityCard
        key={form.id}
        startTime={form.createdAt || ''}
        journeyName={journey.isPartOf?.name}
        icon={Web}
      >
        Visited form <em>{form.identifier}</em>
      </ActivityCard>,
      ..._.flatMap(form.shopperAnswers, (q: QuestionAndAnswers) =>
        q.answers.map(answer => (
          <ActivityCard
            key={answer.id}
            startTime={answer.createdAt || ''}
            journeyName={journey.isPartOf?.name}
            icon={Send}
          >
            Answered question <b>{JSON.stringify(q.question)}</b> with{' '}
            <b>{JSON.stringify(answer.answer)}</b>
          </ActivityCard>
        ))
      )
    ])
  ])
}

export function getCallCampaignSummaries(journeys: Journey[]) {
  return _.flatMap(journeys, journey => [
    ..._.flatMap(journey.callCampaignSummaries, callCampaignSummary => [
      <ActivityCard
        key={callCampaignSummary.id}
        startTime={callCampaignSummary.createdAt || ''}
        journeyName={journey.isPartOf?.name}
        icon={Phone}
        variant={'info'}
        leadId={callCampaignSummary.leadId}
        leadType={callCampaignSummary.leadType}
      >
        Call campaign summary: {pluralize(callCampaignSummary.numberOfCalls, 'call')}
        {callCampaignSummary.policySold ? ', policy sold' : ''}
        {callCampaignSummary.wasTransferred ? ', transferred' : ''}
        {callCampaignSummary.disambiguatingDescription
          ? ', ended reason: ' + callCampaignSummary.disambiguatingDescription
          : ''}
      </ActivityCard>
    ])
  ])
}

export function getPipelineAppointments(communicateActions: CommunicateAction[]) {
  return _.chain(communicateActions)
    .filter(o => o.instrument === Instrument.PIPELINE && o.about === 'appointment')
    .map(o => {
      const props = getCommonActivityCardProps(o)
      const actionStatusWords = _.words(o.actionStatus)
      const actionVerb = _.last(actionStatusWords)
      return (
        <ActivityCard {...props} key={o.id} icon={CalendarMonth} variant='info'>
          <Typography variant='body2' mt={0.25} gutterBottom>
            <strong>Pipeline {actionStatusWords.join(' ')}</strong> ({o.object?.about})
          </Typography>
          <Typography variant='body2' gutterBottom>
            {actionVerb !== 'created' && _.upperFirst(actionVerb)} Appointment Date:{' '}
            {dayjs(o.object?.startTime).format('M/D/YYYY @ h:mma')}
          </Typography>
          {o.agent && (
            <Typography variant='body2'>
              {_.upperFirst(actionVerb)} {' by: '}
              <AgentLink agent={o.agent} disambiguatingDescription={o.disambiguatingDescription} />
            </Typography>
          )}
        </ActivityCard>
      )
    })
    .value()
}

export function insertTimeDividers(activities: React.ReactNode[]) {
  const withTimeDividers: React.ReactNode[] = []
  activities.forEach((current, i) => {
    const prev = activities[i - 1]
    if (
      prev &&
      dayjs(prev?.['props'].startTime).diff(current?.['props'].startTime, 'day') >= DIVIDER_MIN_DAYS
    ) {
      withTimeDividers.push(
        <TimeDivider key={current?.['props'].startTime} datetime={current?.['props'].startTime} />
      )
    }
    withTimeDividers.push(current)
  })
  return withTimeDividers
}

export function getStartTimeForAction(action: CommunicateAction): string {
  // OMNI-191: always prefer "startTime" to "createdAt" if available
  return action.startTime || action.createdAt || ''
}

export function getCommonActivityCardProps(action: CommunicateAction, startTime?: string) {
  return {
    key: action.id,
    startTime: startTime || getStartTimeForAction(action),
    journeyName: action.journeyName,
    leadId: action.leadId,
    leadType: action.leadType
  }
}
