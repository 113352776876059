import InfiniteScroll from 'react-infinite-scroll-component'
import React, { useEffect, useRef, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { Alert, AlertTitle } from '@mui/material'

import { Actions, State, useStoreActions, useStoreState } from 'easy-peasy'
import { Loading } from '@assuranceiq/react-components'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useLazyQuery } from '@apollo/client'

import { Breadcrumbs } from '../components/Breadcrumbs'
import { ChatMessage } from '../components/ChatMessage'
import type { CommunicateAction, CommunicateActionOrJourneyStatus, JourneyStatus } from '../types'
import { EventType, Instrument, SortOrder } from '../enums'
import { JOURNEY_HISTORY_QUERY } from '../graphql/queries/journey-history'
import { JourneyHistoryFilters } from '../components/JourneyHistoryFilters'
import { JourneyHistorySortOptions } from '../components/JourneyHistorySortOptions'
import { JourneyStatusCard } from '../components/ActivityCard/JourneyStatusCard'
import type { StoreModel } from '../utils/store'
import { TimeDivider } from '../components/TimeDivider'
import { createQueryUrl } from '../utils/search-query'
import { getStartTimeForAction, getTelephoneActions } from '../utils/timeline'

import styles from './Journey.module.scss'

const ACTIONS_PER_PAGE = 50 // Note: backend max is 50 per page

export function Journey() {
  const { journeyName = '' } = useParams()

  const eventFilter = useStoreState((state: State<StoreModel>) => state.settings.eventFilter)
  const { setEventFilter } = useStoreActions((actions: Actions<StoreModel>) => actions.settings)
  const sortOrder = useStoreState((state: State<StoreModel>) => state.settings.sortOrder)
  const { setSortOrder } = useStoreActions((actions: Actions<StoreModel>) => actions.settings)

  console.log('Rendering Journey page')
  return (
    <div>
      <div className={`animate__animated animate__fadeIn ${styles.header}`}>
        <Breadcrumbs root='Journeys' title={journeyName || 'Unknown Journey'} />
        <JourneyHistorySortOptions sortOrder={sortOrder} onChange={setSortOrder} />
      </div>
      <div className={styles.content}>
        <JourneyHistoryFilters eventTypes={eventFilter} onChange={setEventFilter} />
        <History journeyName={journeyName} eventFilter={eventFilter} sortOrder={sortOrder} />
      </div>
    </div>
  )
}

function History({ journeyName, eventFilter, sortOrder }) {
  const [events, setEvents] = useState<CommunicateActionOrJourneyStatus[]>([])
  const [isEnd, setIsEnd] = useState<boolean>(false)

  const didMountRef = useRef(false)

  const [doQuery, { loading, error, data, called }] = useLazyQuery(JOURNEY_HISTORY_QUERY, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true // skip on mount
      return
    }
    if (loading) return
    const newEvents: CommunicateAction[] = _.get(data, 'journeyHistory.events', [])
    if (newEvents.length === 0) {
      setIsEnd(true)
    } else {
      setEvents(_.flatten([...events, ...newEvents]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const fetch = (eventTypeFilter: EventType[], after?: string, sortOrder?: SortOrder) =>
    doQuery({
      variables: { journeyName, limit: ACTIONS_PER_PAGE, after, eventTypeFilter, sortOrder }
    })

  // reset states and fetch whenever eventFilter changes (including initial mount)
  useEffect(() => {
    setIsEnd(false)
    setEvents([])
    fetch(eventFilter, _.last(events)?.id, sortOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilter, sortOrder])

  if (!called && loading) {
    return <Loading /> // initial loading; subsequent loading will be handled by InfiniteScroll
  }

  if (error) console.error('Error while querying journey history!', error)
  if (!loading && _.isEmpty(events)) {
    return (
      <Alert className={styles.alert} severity='warning'>
        <AlertTitle>No events to display!</AlertTitle>
        Check the filters or come back at a later time.
      </Alert>
    )
  }

  return (
    <InfiniteScroll
      dataLength={events.length} // important field to render the next data
      next={() => fetch(eventFilter, _.last(events)?.id, sortOrder)}
      hasMore={called && !isEnd && events.length % ACTIONS_PER_PAGE === 0}
      loader={<Loading />}
    >
      {getActivityCards(events, journeyName)}
    </InfiniteScroll>
  )
}

function getActivityCards(events: CommunicateActionOrJourneyStatus[], journeyName: string) {
  const activityCards: React.ReactNode[] = []
  events.forEach((current, i) => {
    const typename = _.get(current, '__typename')
    const eventTime = getEventTime(current, typename)
    const prev = events[i - 1]
    // insert a TimeDivider whenever we see a new/different date
    if (prev && eventTime && !dayjs(getEventTime(prev, typename)).isSame(eventTime, 'day')) {
      activityCards.push(<TimeDivider key={eventTime} datetime={eventTime} />)
    }
    if (typename === 'CommunicateAction') {
      const event = current as CommunicateAction
      activityCards.push(
        <RouterLink
          key={event.id}
          className={styles.activity}
          to={createQueryUrl(event.leadId || '')}
        >
          {event.instrument === Instrument.SMS && (
            <ChatMessage action={event} startTime={getStartTimeForAction(event)} />
          )}
          {event.instrument === Instrument.TELEPHONE && getTelephoneActions([event]).pop()}
        </RouterLink>
      )
    } else if (typename === 'JourneyStatus') {
      const event = current as JourneyStatus
      activityCards.push(
        <RouterLink
          key={event.id}
          className={styles.activity}
          to={createQueryUrl(event.leadId || '')}
        >
          <JourneyStatusCard
            key={event.createdAt + event.status}
            status={event}
            journeyName={journeyName}
            startTime={event.createdAt || ''}
          />
        </RouterLink>
      )
    }
  })
  return activityCards
}

function getEventTime(event: CommunicateActionOrJourneyStatus, typename: string) {
  if (typename === 'CommunicateAction') return _.get(event, 'startTime')
  if (typename === 'JourneyStatus') return _.get(event, 'createdAt')
  return null
}
