import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'

import { AGENT_SUMMARY_QUERY } from '../graphql/queries/agent-summary'
import { SHOPPERS_QUERY } from '../graphql/queries/shoppers'

interface QueryFilter {
  email?: string
  telephone?: string
  joinAgentId?: string
  leadId?: string
}

interface JourneyQueryProps {
  children: ({ loading, data, error }) => React.ReactNode
  filter: QueryFilter
  query: string
}

enum QueryType {
  AGENT_TYPE = 'AGENT_QUERY',
  SHOPPER_TYPE = 'SHOPPER_QUERY'
}

export default function JourneyQuery(props: JourneyQueryProps) {
  const { children, filter, query } = props
  const [queryType, setQueryType] = useState<QueryType>(QueryType.SHOPPER_TYPE)
  const [queryShoppers, { loading: shopperLoading, error: shopperError, data: shopperData }] =
    useLazyQuery(SHOPPERS_QUERY)

  const [queryAgent, { loading: agentLoading, error: agentError, data: agentData }] =
    useLazyQuery(AGENT_SUMMARY_QUERY)

  useEffect(() => {
    if (!query) {
      return
    }
    if (filter.joinAgentId) {
      queryAgent({ variables: filter })
      setQueryType(QueryType.AGENT_TYPE)
    } else {
      queryShoppers({ variables: { filter } })
      setQueryType(QueryType.SHOPPER_TYPE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  if (shopperError) console.error('Error while querying shoppers!', shopperError)
  if (agentError) console.error('Error while querying agents!', agentError)

  if (queryType === QueryType.SHOPPER_TYPE) {
    return <>{children({ loading: shopperLoading, data: shopperData, error: shopperError })}</>
  } else {
    return <>{children({ loading: agentLoading, data: agentData, error: agentError })}</>
  }
}
