import { useState } from 'react'

import { FormControl, IconButton, InputAdornment, OutlinedInput, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import _ from 'lodash'

import styles from './SearchBar.module.scss'

interface SearchBarProps {
  defaultQuery?: string
  onSearch?: (query: string) => void
}

export function SearchBar(props: SearchBarProps) {
  const { defaultQuery = '', onSearch = _.noop } = props
  const [query, setQuery] = useState<string>(defaultQuery)

  return (
    <FormControl className={styles.root} variant='outlined' size='small'>
      <Tooltip
        title='Search by email, phone, lead ID, or Join Agent ID'
        placement='right'
        enterDelay={350}
        enterNextDelay={350}
        arrow
      >
        <OutlinedInput
          placeholder='Look up a shopper or agent...'
          type='text'
          value={query}
          onChange={e => setQuery(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={() => onSearch(query.trim())} edge='end'>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ autoComplete: 'off' }}
          onKeyPress={e => {
            if (e.key === 'Enter') onSearch(query.trim())
          }}
          sx={{ backgroundColor: 'white' }}
        />
      </Tooltip>
    </FormControl>
  )
}
