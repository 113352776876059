import { Alert } from '@mui/material'
import { Chat } from '@mui/icons-material'

import _ from 'lodash'

import { ActivityCard } from '../ActivityCard'
import type { LeadInteraction } from '../../types'
import LogRocket from '../../utils/logrocket'

export function AgentTimeline({ data }) {
  LogRocket.track('AgentTimeline') // we want to track how often this view gets hit

  const { edges } = data?.agentShopperSummary || {}

  if (!edges.length) return <div>Nothing to show here..!</div>

  const interactions: LeadInteraction[] = _.chain(edges)
    .map('node')
    .sortBy('lastCommunicatedAt')
    .reverse()
    .value()

  return (
    <div>
      <Alert severity='info' sx={{ mb: 2 }}>
        Currently, the <em>Agent Timeline</em> is a beta feature. We can only query by one specific
        date at a time, so you are only seeing activities from the "last day" in which the agent was
        active.
      </Alert>

      {interactions.map(o => (
        <ActivityCard
          startTime={o.lastCommunicatedAt}
          key={o.leadId}
          icon={Chat}
          leadId={o.leadId}
          leadType={o.leadType}
          routeLeadId
        >
          Communicated with a shopper.
          {o.lastCommunicationAction && (
            <>
              {' '}
              Indicated <b>{o.lastCommunicationAction}</b>
            </>
          )}
        </ActivityCard>
      ))}
    </div>
  )
}
