import { gql } from '@apollo/client'

import { AGENT_FRAGMENT } from './agent-fragment'
import { ORGANIZATION_FRAGMENT } from './organization-fragment'

export const POLICY_FRAGMENT = gql`
  fragment PolicyFragment on Policy {
    id
    disambiguatingDescription
    createdAt
    updatedAt
    startDate
    endDate
    agent {
      ...AgentFragment
    }
    policy {
      id
      status
      enrollmentStatus
      createdAt
      updatedAt
      policyId
      name
      broker {
        ...OrganizationFragment
      }
      provider {
        ...OrganizationFragment
      }
    }
  }
  ${AGENT_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
`
