interface LoiIconProps {
  loi: string // leadType
}

export function LoiIcon(props: LoiIconProps) {
  const { loi } = props
  switch (loi) {
    case 'medicare':
      return <img src='/images/medicare.svg' alt={loi} />
    case 'health':
      return <img src='/images/health.svg' alt={loi} />
    case 'life':
      return <img src='/images/life.svg' alt={loi} />
    case 'auto':
      return <img src='/images/auto.svg' alt={loi} />
    case 'mortgage':
    case 'home':
      return <img src='/images/mortgage.svg' alt={loi} />
    default:
      return <>{loi}/</>
  }
}
