import { Error } from '@mui/icons-material'

import { ActivityCard } from '../ActivityCard'
import type { CommunicateAction } from '../../types'

import styles from './UnhandledActionStatusError.module.scss'

interface UnhandledActionStatusErrorProps {
  action: CommunicateAction
}

export function UnhandledActionStatusError({ action }: UnhandledActionStatusErrorProps) {
  return (
    <ActivityCard key={action.id} startTime={action.createdAt || ''} icon={Error} variant='error'>
      <b>{action.actionStatus}</b> - unhandled action status
      <pre className={styles.compactJSON}>{JSON.stringify(action, null, 2)}</pre>
    </ActivityCard>
  )
}
